import React from 'react';
import ContextMenu from '../ContextMenu.component';
import { Link, NavLink, withRouter } from 'react-router-dom';
import userStore from '../../../stores/user.store';
import { InviteMemberModal } from '../modalComponents';
import utilsService from '../../../services/utils.service';
import VersionShowComponent from '../VersionShow.component';
import { AddButton, ChatsNew, BellUnselectedIcon2, LogoIcon3 } from '../svg';
import Notifications from '../notifications/Notifications.component';
import ConversationsNavLink from './ConversationsNavLink.component';
import RecruiterSearchComponent from '../search/RecruiterSearch.component';
import './recNavigationBar.scss';
import DefaultImageComponent from '../DefaultImage.component';
import ProjectsMenuComponent from '../projectsBucket/projectsMenu/ProjectsMenu.component';
import notificationsBellStore from '../../../stores/notificationsAlerts.store';
import { observer } from 'mobx-react';
import CreateEditUser from '../../scenes/settings/CreateEditUser.component';
import profileStore from '../../../stores/profile.store';
import notificationsStore from '../../../stores/notifications.store';
import FancySwitchButton2Component from '../fancySwitchButton2/fancySwitchButton2.component';
import NavIcon from './NavIcon.component';
import { Button, Collapse, Icon } from '@blueprintjs/core';

@observer
class RecruiterNavigationBar extends React.PureComponent {

    static logOut() {
        userStore.logOut();
    }

    constructor(props) {
        super(props);
        this.state = {
            isModalOpen_Invite: false,
            showMenuMobile: false,
            showSearchMobile: false,

            isModalOpen_createEditUser: false,
            createEditUserShowLoading: false,
            createEditUserShowError: false,

            showVersion: false
        };
    }
    
    handleContactClick = async () => {
        this.setState({ isModalOpen_createEditUser: true });
        const user = userStore.user;
        await profileStore.getProfileById(user.profileId);
    }

    handleInviteUser = async (userData, isEdit) => {
        this.setState({ createEditUserShowLoading: true, createEditUserShowError: false  });
        const response = await userStore.createUser(userData);
        if(response?.isError) {
            this.setState({ createEditUserShowLoading: false, createEditUserShowError: response.errorMessage });
        }

        this.setState({ createEditUserShowLoading: false });

        if(!response?.isError) {
            this.setState({ isModalOpen_createEditUser: false });
            notificationsStore.showToast(`Create User Completed Successfully`, 'success');
        }
    }

    handleStatusCheckBoxChanged = (e) => {
        const isOnlineUser = userStore.onlineRecruiters[userStore.user._id];
        userStore.changeCurrentRecruiterStatus({status: (isOnlineUser ?  0 : 1)})
    }

    render() {

        const { createEditUserShowLoading, createEditUserShowError, profileStatus, showSearchMobile } = this.state

        const user = userStore.user;
        let profileName;
        if (user) {
            profileName = utilsService.getDisplayName(user);
        }

        const name = user ? user.displayName : 'no user logged';
        let wrapperStyles = 'top-black-bar recruiter';

        const isRecruiter = utilsService.isRecruiter(userStore.user);

        let totNotifications = 0;
        if(notificationsBellStore.notifications && notificationsBellStore.notifications.length > 0) {
            const unreadNotifications = notificationsBellStore.notifications.filter(notification => !notification.isRead);
            totNotifications = unreadNotifications ? unreadNotifications.length : 0;
        }

        const isOnlineUser = userStore.onlineRecruiters[userStore.user._id];
        return (
            <header className={wrapperStyles}>
                <nav className="rec-navigation-bar max-width">
                    <NavIcon 
                        isActive={this.state.showMenuMobile} 
                        onClick={() => this.setState({ showMenuMobile: !this.state.showMenuMobile })} 
                    />

                    {/*LOGO*/}
                    <Link to={isRecruiter ? '/dashboard' : '/'} className='logo'>
                        <LogoIcon3/>
                    </Link>


                    {/*NAVIGATION LINKS*/}
                    <div className={`navigation-menu ${this.state.showMenuMobile ? 'show' : ''}`}>
                        <ConversationsNavLink onCloseMenu={() => this.setState({ showMenuMobile: false })} />
                        <NavLink to="/candidates" activeClassName='active' className='candidates'>Candidates</NavLink>
                        <NavLink to="/jobs" activeClassName='active' className='jobs'>Jobs</NavLink>
                        <NavLink to="/companies" activeClassName='active' className='companies'>Companies</NavLink>
                        <NavLink to="/matches" activeClassName='active' className='companies'>Matches</NavLink>
                        
                        <NavLink to="/notifications" activeClassName='active' className='notifications'>
                            Notifications ({totNotifications})
                            
                        </NavLink>
                        <NavLink to="/search" activeClassName='active' className='search'>Search</NavLink>
                        {/*<ProjectsMenuComponent/>*/}

                    </div>

                    <div className="menu-right">
                        <Collapse isOpen={showSearchMobile} keepChildrenMounted className='search-recruiter-collapse'>
                            <RecruiterSearchComponent onClose={() => this.setState({showSearchMobile : false})} />
                        </Collapse>

                        <Button 
                            hidden
                            small
                            minimal
                            icon={<Icon icon='search' size={14} />} 
                            className={`search-recruiter-toggle ${showSearchMobile ? 'show' : ''}`}
                            onClick={() => this.setState({ showSearchMobile: !showSearchMobile })}
                        />

                        {/*BUTTONS */}

                        <div className="icons hide">
                            <div className="chat-notification">
                                <ChatsNew/>
                            </div>
                            <Notifications/>
                        </div>
                        {/* <div className="buttons">
                            {permissionsService.canInviteTeam(user) && !isUserRecruiter && (
                                <button className="button bk-white-less invite"
                                        onClick={() => this.setState({ isModalOpen_Invite: true })}>
                                    Invite
                                </button>
                            )}
                            <Link className='button bk-white-less' to="/new-job">
                                New Job
                            </Link>
                        </div> */}


                        <div className="icon-menu add-icon">
                            <div className='menu-container add-menu'>
                                <ContextMenu top="45">
                                    <div>
                                        <div className='menu-item'>
                                            <Link to='/new-job'>
                                                Job
                                            </Link>
                                        </div>
                                        <div className='menu-item'>
                                            <Link to='/create-new-profile'>
                                                Candidate
                                            </Link>
                                        </div>
                                        <div className='menu-item'>
                                            <Link to='/create-new-company'>
                                                Company
                                            </Link>
                                        </div>
                                        <div className='menu-item' onClick={(e) => this.handleContactClick()}>
                                            User
                                        </div>
                                        <div className='menu-item'>
                                            Task
                                        </div>
                                        <div className='menu-item'>
                                            Event
                                        </div>
                                        <div className='menu-item'>
                                            Email
                                        </div>
                                    </div>
                                </ContextMenu>
                                <div className="add-round-button2">
                                    <AddButton />
                                </div>
                            </div>
                        </div>
                        {/*USER MENU*/}

                        <div className="bell-icon" onClick={() => this.props.history.push('/notifications')}>
                            {/* <BellIcon/> */}
                            <BellUnselectedIcon2/>
                            {totNotifications > 0 && (
                                <span className="tot-notification-bell">{totNotifications}</span>
                            )}
                        </div>

                        <div className="icon-menu profile-img">
                            {user && (<div className='menu-container'>
                                <ContextMenu top="70">
                                    <div>
                                        <div className='menu-header'>
                                            <span className="profile-name-capitalized">{name}</span>
                                        </div>
                                        <div className='menu-item status'>
                                            <label className='menu-item-status-title'>Status:</label>
                                            <FancySwitchButton2Component
                                                isLeftSideLabel={true}
                                                lables={['Online', 'Away']}
                                                checked={isOnlineUser}
                                                id={'status'}
                                                checkAction={e => this.handleStatusCheckBoxChanged(e)}
                                            />
                                        </div>
                                        <div className='menu-item profile'>
                                            <Link to='/user/details'>
                                                My Profile
                                                </Link>
                                        </div>
                                        <div className="menu-item">
                                            Invite
                                            </div>
                                        <div className="menu-item">
                                            Social Networking
                                            </div>
                                        <div className="menu-item">
                                            <Link to='/billingList'>
                                                Billing
                                                </Link>
                                        </div>
                                        <div className='menu-item'>
                                            <Link to='/settings'>
                                                Settings
                                                </Link>
                                        </div>
                                        <div className="menu-item">
                                            Analytics
                                            </div>
                                        <div className="menu-item">
                                            <a
                                                target='_blank'
                                                rel='noopener noreferrer'
                                                href="https://chrome.google.com/webstore/detail/brightsource/jcekocpilfhlkmmhlomdahegbgjaeana">Chrome
                                                    Extension</a>
                                        </div>
                                        <div className="menu-item">
                                            Feedback
                                            </div>
                                        <div className='menu-item log-out'>
                                            <a onClick={() => RecruiterNavigationBar.logOut()}>
                                                Log Out
                                                </a>
                                        </div>
                                    </div>
                                </ContextMenu>
                                <div className="profile-pic">
                                    <DefaultImageComponent
                                        url={utilsService.getProfileImage(user)}
                                        alt={profileName}
                                        profileName={profileName}
                                        isExposed={true}
                                    />
                                </div>
                            </div>
                            )}
                        </div>
                    </div>

                </nav>

                {this.state.isModalOpen_Invite && (
                    <InviteMemberModal
                        closeEvent={() => this.setState({ isModalOpen_Invite: false })}
                    />
                )}

                {this.state.isModalOpen_createEditUser && (
                    <CreateEditUser
                        isError={createEditUserShowError}
                        screenType={'create'}
                        user={null}
                        profile={null}
                        isLoading={createEditUserShowLoading}
                        onClick={(userData) => this.handleInviteUser(userData, false)}
                        onClose={() => this.setState({ isModalOpen_createEditUser: false, createEditUserShowError: false })}
                    />
                )}


                {this.state.showVersion && (
                    <VersionShowComponent/>
                )}
            </header>
        );
    }
}

export default withRouter(RecruiterNavigationBar);
