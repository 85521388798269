import { Autocomplete, BsTable, BsTableNoContent, Button, DateInput, Loader, Menu, TextField } from '@brightsource/brightsource-ui-lib';
import React, { useMemo, useState, useEffect } from 'react';
import styled from 'styled-components';
import { ResponsiveDevice } from '../../../constants/styles';
import { AddIcon, MoreIcon, SearchNormal, TrashIcon1 } from '../../components/svg';
import remindersStore from '../../../stores/reminders.store';
import qs from 'qs';
import DefaultImageComponent from '../../components/DefaultImage.component';
import utilsService from '../../../services/utils.service';
import userStore from '../../../stores/user.store';
import AddReminderModalComponent from '../../components/modalComponents/AddReminderModal.component';

function DashboardReminders() {
  const [reminders, setReminders] = useState([]);
  const [loading, setLoading] = useState(false);
  const [typingTimeout, setTypingTimeout] = useState(null);

  const [filters, setFilters] = useState({dueDate: null, assignee: null, search: ''});
  const recruitersList = userStore.recruitersList

  const [modal, setModal] = useState({
    createReminder: false
  });

  const dueDateOptions = 
  [
    {
      elId: 'today',
      value: 'Due Today'
    },
    {
      elId: 'overdue',
      value: 'OverDue'
    },
    {
      elId: 'upcoming',
      value: 'Upcoming'
    }
  ]


  const columns = useMemo(() => {
    return [
      { id: 'object', label: 'Object' },
      { id: 'duedate', label: 'Due Date' },
      { id: 'reminderdate', label: 'Reminder Date' },
      { id: 'subject', label: 'Subject' },
      { id: 'assignedTo', label: 'Assigned To' },
      { id: 'actions', label: 'Action' },
    ];
  }, []);

  useEffect(() => {
    (async function loader() {
      await getListReminder();
    })()
  }, [filters]);

  const getListReminder = async () => {
    setLoading(true);
    if (recruitersList.length == 0) {
      userStore.getRecruiters()
    }
    const payload = {
      dueDate: filters?.dueDate?.elId,
      assignee: filters?.assignee?.slug,
      keyword: filters?.search
    }
    const listReminders = await remindersStore.getlistReminders(qs.stringify(payload))
    setReminders(listReminders)
    setLoading(false)
  }


  const rows = useMemo(() => {
    return reminders.map((reminder, index) => {
      const assignee = recruitersList.find(i => i.slug == reminder.assigneeSlug);
      return {
        id: index,
        object: (
          <div className='object-card'>
            <div className='object-image'>
              {reminder.entityType == 'profile' ?
              <DefaultImageComponent
                url={utilsService.getProfileImage({profileImageUrl: reminder.entityData.imageURL})}
                alt={reminder.entityData.candidateName}
                profileName={reminder.entityData.candidateName}
              />
              : 
              <DefaultImageComponent
                url={utilsService.getCompanyImage({profileImageUrl: reminder.entityData.imageURL})}
                alt={'Company'}
                company={true}
              />}
            </div>
            <div className='object-infor'>
              <span className='name'>{reminder.entityType == 'profile' ? reminder.entityData.candidateName : reminder.entityData.jobName}</span>
              <span className='position'>{reminder.entityType == 'profile' ? reminder.entityData.position : reminder.entityData.companyName}</span>
              <span className='location'>{`${reminder.entityData.city} - ${reminder.entityData.country}`}</span>
            </div>
          </div>
        ),
        duedate: utilsService.getFormattedDate(reminder.dueDate, 5),
        reminderDate: utilsService.getFormattedDate(reminder.reminderDate, 5),
        subject: reminder.detail,
        assignedTo: assignee?.firstName + " " + assignee?.lastName,
        actions: (
          <Actions>
            <TrashIcon1 />

            <Menu showAsIcon items={[]}>
              <MoreIcon />
            </Menu>
          </Actions>
        ),
      };
    });
  }, [reminders]);

  return (
    <Wrapper>

      <div className='filter-bar'>

        <Autocomplete
            freeSolo
            value={filters?.dueDate}
            onChange={(e, item) => {setFilters({...filters, dueDate: item})}}
            options={dueDateOptions}
            popupIcon={false}
            clearIcon={false}
            forcePopupIcon={false}
            getOptionLabel={(option) => `${option.value || ''}`}
            label='Due Date'
            placeholder=""
        />

        <Autocomplete
            freeSolo={true}
            value={filters?.assignee}
            onChange={(e, item) => {setFilters({...filters, assignee: item})}}
            options={recruitersList.map(i => ({...i, elId: i._id, value: utilsService.getDisplayName(i)}))}
            popupIcon={false}
            clearIcon={false}
            forcePopupIcon={false}
            getOptionLabel={(option) => `${option.value || ''}`}
            label='Assigned To'
        />

        <TextField
          size="small"
          value={filters.search}
          onChange={(event) => setFilters({...filters, search: event.target.value})}
          allowClear
          onClear={() => setFilters({...filters, search: '' })}
          rightIcon={<SearchNormal w={16} h={16} />}
          placeholder="Search"
        />

        <div className="new-btn">
          <Button
            minimal
            rightIcon={<AddIcon fill="#fff" />}
            onClick={() => setModal((modal) => ({ ...modal, createReminder: true }))}
          >
            New Task
          </Button> 
        </div>


        {modal.createReminder && (
          <AddReminderModalComponent
            buttonText="YES"
            cancelButtonText="NO"
            closeEvent={() => setModal((modal) => ({ ...modal, createReminder: false }))}
            refresh={() => getListReminder()}
          />
        )}
      </div>

      <Content>
        <BsTable columns={columns} rows={rows} disabledSortColumns={['actions']} />
        {loading && <Loader />}
        {!loading && rows.length === 0 && <BsTableNoContent />}
      </Content>
    </Wrapper>
  );
}

export default DashboardReminders;

const Wrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;

  .default-pic-frame,
  .default-image-component > img {
    width: 60px;
    height: 60px;
    object-fit: cover;
    border-radius: 50%;
    font-size: 16px;

    ${ResponsiveDevice.TABLET} {
      width: 56px;
      height: 56px;
    }
  }

  .default-image-component > svg {
    width: 60px;
    height: 60px;
    ${ResponsiveDevice.TABLET} {
      width: 56px;
      height: 56px;
    }
  }

  th.actions-cell {
    text-align: right;
    padding-right: 24px;
  }

  .object-card {
    display: flex;
    justify-content: start;
    align-items: center;
    .object-image {
      margin-right: 15px
    }
    .object-infor {
      display: flex;
      flex-direction: column;
      .name {
        text-transform: capitalize;
        font-size: 20px;
        font-weight: 600;
      }
    }
  }
  .filter-bar {
    padding: 16px 24px;
    display: flex;
    align-items: center;
    gap: 40px;
    background-color: #fff;
    border-radius: 12px 12px 0px 0px;

    .autocomplete,
    .text-field,
    .date-input {
      width: 250px;
      margin-bottom: 0px;
    }

    .text-field {
      margin-top: 35px;
    }

    .new-btn {
      margin-left: auto;
      button {
        margin-top: 35px;
        padding: 10px 24px;
        border-radius: 60px;
        background-color: #307ff6;
        transition: all 100ms ease-in-out;
  
        color: #fff;
        font-size: 16px;
        font-weight: 400;
        line-height: 100%;
  
        &:hover {
          color: #fff;
          background-color: #002a84;
        }
      }
    }

  }
`;

const Content = styled.div`
  flex: 1;
  background-color: #fff;
  border-radius: 0px 0px 12px 12px;

  .bs-menu {
    text-align: right;
  }

  a.name {
    color: #6c6c6c;
    transition: color 150ms ease-in-out;

    &:hover {
      color: #307ff6;
    }
  }

  ${ResponsiveDevice.TABLET} {
    .MuiTableHead-root {
      display: none;
    }

    .MuiTableCell-root {
      /* padding: 8px; */
      vertical-align: top;
    }
  }
`;

const Actions = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;
