import { action, observable } from 'mobx';
import fetchService from '../services/fetch.service';
import userStore from './user.store';
import dayjs from 'dayjs';
import errorStore from './error.store';
import crypto from 'crypto';

class RemindersStore {
    @observable listReminders = {};

    async deleteReminder(id) {
        const reminder = await fetchService.authenticatedPatch(`/reminder/${id}`, {isDeleted: true});

    }

    async updateReminder(id, body) {
        const reminder = await fetchService.authenticatedPatch(`/reminder/${id}`, body);
    }

    @action.bound
    async getlistReminders(query) {
        const reminders = await fetchService.authenticatedGet(`/reminder?${query}`);
        return reminders.data.data;
    }

    async createReminder(body) {
        return await fetchService.authenticatedPost('/reminder', body);
    }
}

const remindersStore = new RemindersStore();

export default remindersStore;
