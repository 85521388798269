import React, { PureComponent } from 'react';
import searchStore from '../../../stores/search.store';
import ModalComponent from '../modal/Modal.component';
import notificationsStore from '../../../stores/notifications.store';
import { SuggestComponent } from '../../components/forms';
import { RadioGroup, DateInput, Autocomplete } from '@brightsource/brightsource-ui-lib';
import { CalendarIcon1 } from '../svg';
import userStore from '../../../stores/user.store';
import utilsService from '../../../services/utils.service';
import './AddReminderModal.scss';
import remindersStore from '../../../stores/reminders.store';

class AddReminderModalComponent extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            loader: false,
            selectedObject: {},
            listObjects: [],
            objectType: 'profile',
            dueDate: null,
            assignee: null,
            reminderDate: null,
            subject: '',
            isSaving: false,
            isLoading: false
        };
    }
    
    closeModalEvent = () => {
        const { closeEvent } = this.props;
        closeEvent();
    };

    addReminder = async () => {
        this.setState({isSaving: true})

        const payload = {
            entitySlug: this.state.selectedObject?.slug,
            entityType: this.state.objectType,
            dueDate: this.state.dueDate?.getTime(),
            reminderDate: this.state.reminderDate?.getTime(),
            detail: this.state.subject,
            assigneeSlug: this.state.assignee?.slug
        }

        const resp = await remindersStore.createReminder(payload)
        if (resp && resp.data && resp.data.isError) {
            notificationsStore.showToast(`Create new task failed. ${resp.data.errorMessage}`, 'error');
        }
        else {
            notificationsStore.showToast(`Create new task successfully`, 'success');
        }
        this.setState({isSaving: false})
        const { refresh } = this.props;
        refresh();
        this.closeModalEvent()
    }


    getObjects = async(value) => {
        this.setState({isLoading: true})
        if(this.typingTimeout){
            clearTimeout(this.typingTimeout);
        }

        if(value.length >= 2) {
            this.typingTimeout = setTimeout(async () => {
                await searchStore.objectForReminderSearch(this.state.objectType, value);
                const data = this.state.objectType == 'profile' ? searchStore.objectForReminderSearchResults.profiles : searchStore.objectForReminderSearchResults.jobs;
                if (data?.length > 0) {
                    this.setState({listObjects: data.map(item => ({...item, elId: item.slug, value: this.state.objectType == 'profile' ? item.name : item.title}))})
                }
                this.setState({isLoading: false})
            }, 300);
        }
    }

    render() {
        const { loader } = this.state;

        let buttonClasses = 'button darkblue-new';
        if (loader) {
            buttonClasses += ' loading';
        }

        const recruitersList = userStore.recruitersList.map(i => ({...i, elId: i._id, value: utilsService.getDisplayName(i)}))

        const isValid = this.state.selectedObject?.slug && this.state.objectType && this.state.dueDate && this.state.subject && this.state.assignee?.slug

        return (
            <ModalComponent onClose={() => this.closeModalEvent}>
                <div className='modal-box find-profile-modal'>
                    <header>
                        <h2>New task</h2>
                    </header>

                    <div className="form">
                        <div className='object-type'>
                            <RadioGroup
                                name=''
                                items={[{label: "Profile", value: "profile"}, {label: "Job", value: "job"}]}
                                value={this.state.objectType}
                                onChange={(event, value) => {
                                    this.setState({objectType: value})
                                    this.setState({listObjetcs: []})
                                }}
                            />
                        </div>
                        <div className='reminder-infor'>
                            <div className='left-infor'>
                                <SuggestComponent
                                    label={'Object'}
                                    items={this.state.listObjects}
                                    selectedItem={this.state.selectedObject}
                                    selectItem={item => this.setState({selectedObject: item})}
                                    query={this.getObjects}
                                    numberOfCharsToPredicate={2}
                                    isLoading={this.state.isLoading}
                                />
                                <DateInput
                                    value={this.state.dueDate ? new Date(this.state.dueDate) : null}
                                    onChange={(date) => {
                                        this.setState({dueDate: date})
                                    }}
                                    onReset={() => {
                                        this.setState({dueDate: null})
                                    }}
                                    label={'Due Date'}
                                    maxDate={new Date('2100')}
                                    rightIcon={<CalendarIcon1 />}
                                />
                            </div>
                            <div className='right-infor'>
                                <Autocomplete
                                    freeSolo={true}
                                    multiple={false}
                                    value={this.state.assignee}
                                    onChange={(e, item) => {this.setState({assignee: item})}}
                                    options={recruitersList}
                                    popupIcon={null}
                                    clearIcon={null}
                                    forcePopupIcon={false}
                                    getOptionLabel={(option) => `${option.value || ''}`}
                                    label='Assigned To'
                                />
                                <div>
                                    <DateInput
                                        value={this.state.reminderDate ? new Date(this.state.reminderDate) : null}
                                        onChange={(date) => {
                                            this.setState({reminderDate: date})
                                        }}
                                        onReset={() => {
                                            this.setState({reminderDate: null})
                                        }}
                                        label={'Reminder Date'}
                                        maxDate={new Date('2100')}
                                        rightIcon={<CalendarIcon1 />}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className='subject'>
                            Subject
                            <textarea
                                className={'textarea'}
                                value={this.state.subject}
                                onChange={(e) => this.setState({subject: e.target.value})}
                            />
                        </div>
                    </div>
                    <div className='action-button'>
                        <button
                            onClick={this.closeModalEvent}
                            className={'button gray'}>
                            Cancel
                        </button>
                        <button
                            onClick={this.addReminder}
                            disabled={!isValid}
                            className={`button blue ${this.state.isSaving ? 'loading' : ''} ${isValid ? '' : 'disabled'}`}>
                            Save and Submit
                        </button>
                    </div>
                    
                </div>
            </ModalComponent>
        );
    }
}

export default AddReminderModalComponent;
