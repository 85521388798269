import React, {useMemo} from 'react';
import DashboardActivities from './dashboardContent2/dashboardActivities/DashboardActivities.component';
import './dashboard2.scss';
import {useAttachDocumentTitle} from '../../../shared/events/useAttachDocumentTitle';
import {RecruiterLayout} from '../../layouts/RecruiterLayout/RecruiterLayout';
import {Tabs} from '@brightsource/brightsource-ui-lib';
import {AnalyticContainer} from './reports/ReportContainer/AnalyticContainer';
import DashboardReminders from './DashboardReminders';

const Dashboard = (props) => {
    const {location} = props;
    const params = new URLSearchParams(location.search);
    const recruiterIdQuery = params.get('recruiterId');

    const menus = useMemo(() => ([
        { elId: 'Activities', label: 'Activities', content:<DashboardActivities recruiterIdQuery={recruiterIdQuery}/> },
        { elId: 'Analytics', label: 'Analytics', content: <AnalyticContainer />},
        { elId: 'reminders', label: 'Reminders', content: <DashboardReminders />},
    ]), [recruiterIdQuery]);
    useAttachDocumentTitle('Dashboard - Ethosia');

    return (
        <RecruiterLayout>
          <div className='main-dashboard-wrapper'>
            <div className="dashboard-wrapper max-width">
                <Tabs
                  data={menus}
                />
            </div>
          </div>
        </RecruiterLayout>
    )
}

export default Dashboard;
